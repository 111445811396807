import { Catalog } from '@albelli/ecom-promotions-editor/Catalog';
import { DiscountsList } from 'components/discounts/DiscountsOverview/DiscountsOverview';
import { SortingOrder } from 'models/discount';
import { DiscountCodeType, DiscountDefinition } from 'models/discounts/discount';
import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export interface PromotionsPaginatedData<T> {
    items: Record<any, T>;
    total: number;
    batch: number;
}

export const getDiscount = async (discountUUID: string): Promise<DiscountDefinition | null> => {
    const token = await oidc.getAccessToken();
    let discountData = null;
    try {
        discountData = await callApi('PromotionsApiUrl', 'GET', `discounts/${discountUUID}`, null, {}, token);
    } catch (error: any) {
        if (error.responseData.statusCode !== 404) {
            throw error;
        }
    }
    return discountData;
};

export const postDiscount = async (discount: Partial<DiscountDefinition>): Promise<DiscountDefinition> => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'POST', 'discounts', discount, {}, token);
};

export const postDiscountCode = async (discountCode: string, discountUUID: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'PUT', `discounts/${discountUUID}/codes/${discountCode}`, null, {}, token);
};

export const postGenerateDiscountCode = async (discountUUID: string, amountOfCodes: string): Promise<void> => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'POST', `discounts/${discountUUID}/codes`, { count: amountOfCodes }, {}, token);
};

export const getDiscountCodeAudit = async (
    discountUUID: string,
): Promise<{ action: string; data: string; timestamp: string; userId: string }[]> => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'GET', `discounts/${discountUUID}/audits`, null, {}, token);
};

export const deleteDiscountCode = async (
    discountCode: string,
    discountUUID: string,
): Promise<DiscountDefinition | null> => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'DELETE', `discounts/${discountUUID}/codes/${discountCode}`, null, {}, token);
};

export const putDiscount = async (discount: DiscountDefinition) => {
    const token = await oidc.getAccessToken();
    return callApi('PromotionsApiUrl', 'PUT', `discounts/${discount.id}`, discount, {}, token);
};

export const getDiscountCodesByDiscountUuid = async (discountUUID: string): Promise<DiscountCodeType[] | null> => {
    const discountCodesAudit = (await getDiscountCodeAudit(discountUUID)).reverse(); //audits are in reverse order;
    const batchTimeStampArray: { batchId: string; timestamp: string }[] = [];
    let codeTimestampArray: DiscountCodeType[] = [];

    for (const discountAuditObj of discountCodesAudit) {
        if (discountAuditObj.action === 'DiscountCodesGenerated') {
            const discountAuditData: { count: string; id: string; batchId: string } = JSON.parse(discountAuditObj.data);

            const batchId: string = discountAuditData.batchId;
            const timestamp: string = discountAuditObj.timestamp;

            batchTimeStampArray.push({ batchId, timestamp });
        } else if (discountAuditObj.action === 'DiscountCodeAdded') {
            const timestamp: string = discountAuditObj.timestamp;
            const code: string = JSON.parse(discountAuditObj.data).Code;

            codeTimestampArray.push({
                code: code,
                timestamp,
                discountUUID,
            });
        }
    }

    for (const batchTimeStamp of batchTimeStampArray) {
        const batchCodes = await getDiscountCodesByDiscountUuidInternal(
            discountUUID,
            batchTimeStamp.batchId,
            batchTimeStamp.timestamp,
        );

        if (batchCodes && batchCodes.length > 0) {
            codeTimestampArray = codeTimestampArray.concat(batchCodes);
        }
    }
    return codeTimestampArray;
};

const queryDiscountCodes = async (discountUUID, url, token, retryCount = 0) => {
    try {
        return await callApi('PromotionsApiUrl', 'GET', url, null, {}, token);
    } catch (error: any) {
        if (error?.responseData?.statusCode !== 404) {
            if (retryCount < 3) return await queryDiscountCodes(discountUUID, url, token, retryCount++);
            else {
                alert(
                    `Failed to load discount batch ${discountUUID} after 10 retries, error code ${error?.responseData?.statusCode}, skipping.`,
                );
            }
        }
    }
    return [];
};

export const getDiscountCodesByDiscountUuidInternal = async (
    discountUUID: string,
    batchId = '',
    timestamp = '',
): Promise<DiscountCodeType[] | null> => {
    const url = `discounts/${discountUUID}/codes${batchId ? `?batchId=${batchId}` : ''}`;
    const token = await oidc.getAccessToken();
    const discounts: any = await queryDiscountCodes(discountUUID, url, token);
    return discounts.map((discount: string) => {
        return {
            code: discount,
            discountUUID: discountUUID,
            timestamp,
        };
    });
};

export const getDiscountByCode = async (discountCode: string): Promise<DiscountDefinition | null> => {
    const token = await oidc.getAccessToken();
    let discountData = null;
    try {
        discountData = await callApi('PromotionsApiUrl', 'GET', `codes/${discountCode}/discount`, null, {}, token);
    } catch (error: any) {
        //if not found
        if (error.responseData.statusCode !== 404) {
            throw error;
        }
    }
    return discountData;
};

export const getOldDiscountByCode = async (code: string): Promise<DiscountDefinition | null> => {
    const token = await oidc.getAccessToken();
    let discountData = null;
    try {
        discountData = await callApi('PromotionsApiUrl', 'GET', `converter/${code}/discount`, null, {}, token);
    } catch (error: any) {
        if (error.responseData.statusCode !== 404) {
            throw error;
        }
    }
    return discountData;
};

export const getCatalog = (): Promise<Catalog> => {
    return callApi('PromotionsApiUrl', 'GET', 'catalog', null, {}, null);
};

const orderMap = {
    asc: 'Ascending',
    desc: 'Descending',
};

export const getDiscounts = async (
    validAt: string,
    take: number,
    skip: number,
    sortBy?: string,
    sortingOrder?: SortingOrder,
): Promise<DiscountsList> => {
    const token = await oidc.getAccessToken();
    const params = new URLSearchParams();
    params.append('validAt', validAt);
    params.append('take', take.toString());
    params.append('skip', skip.toString());
    if (sortBy && sortingOrder) {
        params.append('sortBy', sortBy);
        params.append('sortingOrder', orderMap[sortingOrder]);
    }
    return callApi('PromotionsApiUrl', 'GET', `discounts/search?${params}`, null, {}, token);
};
