import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import * as React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    title: {
        fontSize: '2rem',
    },
}));
export interface LODOverviewProps {
    optional?: string;
}

const LODOverview: React.FC<LODOverviewProps> = () => {
    const { classes } = useStyles();

    return (
        <Container maxWidth="xl">
            <Box component="div" m={1}>
                <Typography variant="subtitle1" component="h1" className={classes.title}>
                    LOD Home
                </Typography>
            </Box>
        </Container>
    );
};
export default LODOverview;
