import { oidc } from 'services/oidcService';
import { callApi } from './callApi';

export interface SettingsUpdate {
    vendor: string;
    type: string;
    content: string;
}

export type LodFileType = {
    [name: string]: {
        [name: string]: object | string;
    };
};

export const postLodFiles = async (body): Promise<any> => {
    const token = await oidc.getAccessToken();
    return await callApi('LodApiUrl', 'POST', 'repository', body, {}, token);
};

export const getLodFiles = async (): Promise<LodFileType> => {
    const token = await oidc.getAccessToken();
    return await callApi('LodApiUrl', 'GET', 'repository', null, {}, token);
};
