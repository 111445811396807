import { call, put } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import { errorAction } from './errorActions';

/**
 * @param {*} action Redux action
 * @param {*} actualSaga A function accepting action and requestInfo (token and correlationId)
 * @param {*} errorActionType Action type to be dispatched in case of error
 */
function* genericSaga(action, actualSaga, errorActionType) {
    const correlationId = uuidv4();

    try {
        const requestInfo = { correlationId, includeToken: true };
        yield call(actualSaga, action, requestInfo);
    } catch (error) {
        console.log(error);
        yield put(errorAction(errorActionType, error.message, correlationId, action));
    }
}

export const doCall = (actualSaga, errorType) => {
    return function* (action) {
        yield genericSaga(action, actualSaga, errorType, true);
    };
};
