import { productsPerArticletype } from './DiscountDefinitionParserProducts';
export const Keyword = {
    All: 'all',
    Any: 'any',
    Vendor: 'vendor',
    Cart: 'cart',
    Shipping: 'shipping',
    HundredPercent: '100%',
    Percent: '%',
    To: 'to',
    Of: 'of',
    For: 'for',
    ExtraDivider: '/',
    ProductDivider: ',',
    VendorDivider: ',',
    Apply: 'apply',
    With: 'with',

    AllProducts: 'products',
    PhotoBooks: 'photobooks',
    PaddedPhotoBook: 'paddedphotobook',
    CutoutPhotoBook: 'cutoutphotobook',
    MomentsPhotoBook: 'momentsphotobook',
    Canvas: 'canvas',
    Forex: 'forex',
    Aluminium: 'aluminium',
    Perspex: 'perspex',
    Prints: 'prints',
    Poster: 'poster',
    LoveMug: 'lovemug',
    MagicMug: 'magicmug',
    Mug: 'mug',
    Magnet: 'magnet',
    Jigsaw: 'jigsaw',
    Cushion: 'cushion',
    Card: 'card',
    Calendar: 'calendar',
    Photoblock: 'photoblock',
    StudentPlakat: 'studentplakat',
    SpecificProductPrefix: 'pap_',
};

const KeywordSearch = {
    BasePrice: /(base)[\s](price)/,
    TotalPrice: /(total)[\s](price)/,
    CartValue: /(worth)[\s](of)/,
    TargetSplit: new RegExp('\\b(' + Keyword.To + ')\\b', 'i'),
    ProductSplit: new RegExp('\\b(' + Keyword.Of + ')\\b', 'i'),
    VendorCartSplit: new RegExp('\\b(' + Keyword.For + ')\\b', 'i'),
    OfferSplit: new RegExp('\\b(' + Keyword.Apply + ')\\b', 'i'),
    CartSplit: new RegExp('\\b(' + Keyword.With + ')\\b', 'i'),
    VendorWord: new RegExp('\\b(' + Keyword.Vendor + ')\\b', 'i'),
    CartWord: new RegExp('\\b(' + Keyword.Cart + ')\\b', 'i'),
    ShippingWord: new RegExp('\\b(' + Keyword.Shipping + ')\\b', 'i'),
    AllWord: new RegExp('\\b(' + Keyword.All + ')\\b', 'i'),
    SpecificAmountNumber: new RegExp('\\b(\\d+)\\b', 'i'),
    AllProductsWord: new RegExp('\\b(' + Keyword.AllProducts + ')\\b', 'i'),
};

export enum OfferCreationMode {
    SemiAuto = 'semiauto',
    Auto = 'auto',
    Manual = 'manual',
}

export enum MappedDefinitionOfferProductType {
    Unknown = 'unknown',
    UnknownShipping = 'shipping_unknown',
    All = 'all',
    Shipping = 'shipping',
    PhotoBooks = 'photobooks',
    PaddedPhotoBook = 'paddedphotobook',
    CutoutPhotoBook = 'cutoutphotobook',
    MomentsPhotoBook = 'momentsphotobook',
    Canvas = 'canvas',
    Forex = 'forex',
    Aluminium = 'aluminium',
    Perspex = 'perspex',
    Poster = 'poster',
    Prints = 'prints',
    Specific = 'pap_id',
    LoveMug = 'lovemug',
    MagicMug = 'magicmug',
    Mugs = 'mugs',
    Cards = 'cards',
    Calendars = 'calendars',
    Magnets = 'magnets',
    Jigsaw = 'jigsaw',
    Cushions = 'cushions',
    Photoblock = 'photoblock',
    StudentPlakat = 'studentplakat',
}

export const mappedDefinitionAllArticleTypes = [
    MappedDefinitionOfferProductType.PhotoBooks,
    MappedDefinitionOfferProductType.PaddedPhotoBook,
    MappedDefinitionOfferProductType.CutoutPhotoBook,
    MappedDefinitionOfferProductType.MomentsPhotoBook,
    MappedDefinitionOfferProductType.Canvas,
    MappedDefinitionOfferProductType.Forex,
    MappedDefinitionOfferProductType.Aluminium,
    MappedDefinitionOfferProductType.Perspex,
    MappedDefinitionOfferProductType.Poster,
    MappedDefinitionOfferProductType.Prints,
    MappedDefinitionOfferProductType.LoveMug,
    MappedDefinitionOfferProductType.MagicMug,
    MappedDefinitionOfferProductType.Mugs,
    MappedDefinitionOfferProductType.Cards,
    MappedDefinitionOfferProductType.Calendars,
    MappedDefinitionOfferProductType.Magnets,
    MappedDefinitionOfferProductType.Jigsaw,
    MappedDefinitionOfferProductType.Cushions,
    MappedDefinitionOfferProductType.Photoblock,
];

export enum MappedDefinitionOfferType {
    Unknown = 'unknown',
    Percent = 'percent',
}

export enum MappedDefinitionOfferTarget {
    Unknown = 'Unknown',
    All = 'all',
    BasePrice = 'base price',
    TotalPrice = 'total price',
    Extras = 'extras',
}

export interface MappedDefinitionOfferExtras {
    id: string;
    type: 'BOOLEAN' | 'NUMBER';
    value: boolean | number;
}

export interface MappedDefinitionOfferArticleType {
    article_type: string;
    pap_id: Array<string> | null;
    extras: MappedDefinitionOfferExtras | null;
}

export interface MappedDefinitionOffer {
    definition: string;
    product: MappedDefinitionOfferProductType[];
    type: MappedDefinitionOfferType;
    amount: number;
    target: MappedDefinitionOfferTarget | null; //if product == MappedDefinitionOfferTarget.shipping
    extras: MappedDefinitionOfferExtras[] | null; //if target == MappedDefinitionOfferTarget.Extras todo better
    pap_ids: Array<string> | null; //if target == MappedDefinitionTargetType.Specific todo better
}

export interface MappedDefinitionVendor {
    vendors: Array<string>;
    cartCondition: MappedDefinitionCartCondition | null;
    offers: Array<MappedDefinitionOffer>;
}

export interface MappedDefinitionCartCondition {
    product: MappedDefinitionOfferProductType[];
    amount: number;
    type: MappedDefinitionCartConditionType;
}

export enum MappedDefinitionCartConditionType {
    Value = 'value',
    Quantity = 'quantity',
}

export interface MappedDefinition {
    discountCode: string;
    vendorOffers: Array<MappedDefinitionVendor>;
}

function parsePercentage(offer: string): number {
    const split = offer.split(' ');
    for (let i = 0, j = split.length; i < j; i++) {
        const value = split[i].trim();
        let amount = '';
        if (value === '%') {
            amount = split[i - 1].trim();
        } else if (value.includes('%')) {
            amount = value.trim();
        }
        if (amount !== '') return parseInt(amount);
    }
    console.log('value not parsed');
    return 0;
}

function parseProductList(
    products: string,
    amount: number,
    totalPrice: boolean,
    productOffer: string,
): Array<MappedDefinitionOffer> {
    const simple = products.replace(new RegExp('\\s', 'ig'), ',');

    const allProducts = simple.split(',');
    const specificProducts = allProducts.filter(product => product.includes(Keyword.SpecificProductPrefix));
    const categories = allProducts.filter(
        product =>
            !!product &&
            !KeywordSearch.AllWord.test(product) &&
            !KeywordSearch.SpecificAmountNumber.test(product) &&
            !product.includes(Keyword.SpecificProductPrefix),
    );
    const result: Array<MappedDefinitionOffer> = categories.map(product => {
        return {
            product: [parseProductTarget(product)],
            type: MappedDefinitionOfferType.Percent,
            amount: amount,
            target: totalPrice ? MappedDefinitionOfferTarget.TotalPrice : MappedDefinitionOfferTarget.BasePrice,
            extras: null,
            definition: productOffer,
            pap_ids: null,
        };
    });
    if (specificProducts.length) {
        const productCategories = specificProducts
            .map(product => parseProductTargetByPapId(product.toUpperCase()))
            .filter((value, index, array) => array.indexOf(value) === index);
        productCategories.forEach(productCategory => {
            result.push({
                product: [productCategory],
                type: MappedDefinitionOfferType.Percent,
                amount: amount,
                target: totalPrice ? MappedDefinitionOfferTarget.TotalPrice : MappedDefinitionOfferTarget.BasePrice,
                extras: null,
                definition: productOffer,
                pap_ids: specificProducts
                    .filter(x => parseProductTargetByPapId(x.toUpperCase()) === productCategory)
                    .map(x => x.toUpperCase()),
            });
        });
    }

    return result;
}

function parseProductTargetByPapId(papId: string): MappedDefinitionOfferProductType {
    if (productsPerArticletype.Photobooks.includes(papId)) {
        return MappedDefinitionOfferProductType.PhotoBooks;
    }

    if (productsPerArticletype.PaddedPhotoBook.includes(papId)) {
        return MappedDefinitionOfferProductType.PaddedPhotoBook;
    }

    if (productsPerArticletype.CutoutPhotoBook.includes(papId)) {
        return MappedDefinitionOfferProductType.CutoutPhotoBook;
    }

    if (productsPerArticletype.MomentsPhotoBook.includes(papId)) {
        return MappedDefinitionOfferProductType.MomentsPhotoBook;
    }

    if (productsPerArticletype.Canvas.includes(papId)) {
        return MappedDefinitionOfferProductType.Canvas;
    }

    if (productsPerArticletype.Perspex.includes(papId)) {
        return MappedDefinitionOfferProductType.Perspex;
    }

    if (productsPerArticletype.Aluminium.includes(papId)) {
        return MappedDefinitionOfferProductType.Aluminium;
    }

    if (productsPerArticletype.Forex.includes(papId)) {
        return MappedDefinitionOfferProductType.Forex;
    }

    if (productsPerArticletype.Poster.includes(papId)) {
        return MappedDefinitionOfferProductType.Poster;
    }

    if (productsPerArticletype.Cards.includes(papId)) {
        return MappedDefinitionOfferProductType.Cards;
    }

    if (productsPerArticletype.Calendars.includes(papId)) {
        return MappedDefinitionOfferProductType.Calendars;
    }

    if (productsPerArticletype.Prints.includes(papId)) {
        return MappedDefinitionOfferProductType.Prints;
    }

    if (productsPerArticletype.LoveMug.includes(papId)) {
        return MappedDefinitionOfferProductType.LoveMug;
    }
    if (productsPerArticletype.MagicMug.includes(papId)) {
        return MappedDefinitionOfferProductType.MagicMug;
    }

    if (productsPerArticletype.Mugs.includes(papId)) {
        return MappedDefinitionOfferProductType.Mugs;
    }

    if (productsPerArticletype.Magnets.includes(papId)) {
        return MappedDefinitionOfferProductType.Magnets;
    }

    if (productsPerArticletype.Jigsaw.includes(papId)) {
        return MappedDefinitionOfferProductType.Jigsaw;
    }

    if (productsPerArticletype.Cushions.includes(papId)) {
        return MappedDefinitionOfferProductType.Cushions;
    }

    if (productsPerArticletype.Photoblock.includes(papId)) {
        return MappedDefinitionOfferProductType.Photoblock;
    }

    if (productsPerArticletype.StudentPlakat.includes(papId)) {
        return MappedDefinitionOfferProductType.StudentPlakat;
    }

    return MappedDefinitionOfferProductType.Unknown;
}

function parseProductTarget(products: string): MappedDefinitionOfferProductType {
    if (products.includes(Keyword.PhotoBooks)) {
        return MappedDefinitionOfferProductType.PhotoBooks;
    }
    if (products.includes(Keyword.PaddedPhotoBook)) {
        return MappedDefinitionOfferProductType.PaddedPhotoBook;
    }
    if (products.includes(Keyword.CutoutPhotoBook)) {
        return MappedDefinitionOfferProductType.CutoutPhotoBook;
    }
    if (products.includes(Keyword.MomentsPhotoBook)) {
        return MappedDefinitionOfferProductType.MomentsPhotoBook;
    }
    if (products.includes(Keyword.Canvas)) {
        return MappedDefinitionOfferProductType.Canvas;
    }
    if (products.includes(Keyword.Forex)) {
        return MappedDefinitionOfferProductType.Forex;
    }
    if (products.includes(Keyword.Aluminium)) {
        return MappedDefinitionOfferProductType.Aluminium;
    }
    if (products.includes(Keyword.Perspex)) {
        return MappedDefinitionOfferProductType.Perspex;
    }
    if (products.includes(Keyword.Prints)) {
        return MappedDefinitionOfferProductType.Prints;
    }
    if (products.includes(Keyword.Calendar)) {
        return MappedDefinitionOfferProductType.Calendars;
    }
    if (products.includes(Keyword.Poster)) {
        return MappedDefinitionOfferProductType.Poster;
    }
    if (products.includes(Keyword.LoveMug)) {
        return MappedDefinitionOfferProductType.LoveMug;
    }
    if (products.includes(Keyword.MagicMug)) {
        return MappedDefinitionOfferProductType.MagicMug;
    }
    if (products.includes(Keyword.Mug)) {
        return MappedDefinitionOfferProductType.Mugs;
    }
    if (products.includes(Keyword.Card)) {
        return MappedDefinitionOfferProductType.Cards;
    }
    if (products.includes(Keyword.Magnet)) {
        return MappedDefinitionOfferProductType.Magnets;
    }
    if (products.includes(Keyword.Jigsaw)) {
        return MappedDefinitionOfferProductType.Jigsaw;
    }
    if (products.includes(Keyword.Cushion)) {
        return MappedDefinitionOfferProductType.Cushions;
    }
    if (products.includes(Keyword.Photoblock)) {
        return MappedDefinitionOfferProductType.Photoblock;
    }
    if (products.includes(Keyword.StudentPlakat)) {
        return MappedDefinitionOfferProductType.StudentPlakat;
    }
    if (products.includes(Keyword.AllProducts)) {
        return MappedDefinitionOfferProductType.All;
    }
    //if (products.includes(Keyword.SpecificProductPrefix)) return MappedDefinitionOfferProductType.Specific;
    return MappedDefinitionOfferProductType.Unknown;
}

function parsePapIds(products: string): Array<string> {
    const papIds = products.match(/pap_\w*/gi) ?? [];
    return papIds.map(x => x.toUpperCase());
}

function parseExtras(productOffer: string): Array<MappedDefinitionOffer> {
    //25% discount to photobooks/CoverText, 25% discount to photobooks/CoverType of all photobooks
    // or
    // 50% discount to total price of all PAP_347, PAP_324 having PHOTOBOOKS/PremiumLayFlat
    const extrasOffer = productOffer.toLowerCase();
    const split = extrasOffer.split(KeywordSearch.ProductSplit); //of
    const productTarget = parseProductTarget(split[2]); //all photobooks
    const papIdsTarget = parsePapIds(split[2]);

    // Check if about one offer or multiples before split
    const offers =
        (extrasOffer.match(/%/g) || []).length === 1 ? [extrasOffer] : split[0].split(Keyword.ProductDivider); //,
    const results = {};
    for (let i = 0, j = offers.length; i < j; i++) {
        //25% discount to photobooks/CoverText
        const offerSplit = offers[i].split(KeywordSearch.TargetSplit); //to
        const discount = offerSplit[0].trim(); //25% discount
        const targetAndExtra = offerSplit[2]; //photobooks/CoverText
        if (!targetAndExtra.includes(Keyword.ExtraDivider)) continue;
        const splitAgain = targetAndExtra.split(Keyword.ExtraDivider);
        //const target = splitAgain[0]; //photobooks
        const extra = {
            id: splitAgain[1].trim(), //CoverText
            type: 'BOOLEAN', // TODO: Implement other types (e.g. Extra_Pages (number))
            value: true,
        };
        if (results[discount]) {
            results[discount].push(extra);
        } else {
            results[discount] = [extra];
        }
    }
    //flatten / merge by discount value (assumes all same product target, singular 'to' statement)
    const extras: Array<MappedDefinitionOffer> = [];
    for (const discount in results) {
        extras.push({
            product: [productTarget],
            type: discount.includes(Keyword.Percent)
                ? MappedDefinitionOfferType.Percent
                : MappedDefinitionOfferType.Unknown,
            amount: parsePercentage(discount),
            target: MappedDefinitionOfferTarget.Extras,
            extras: results[discount],
            definition: productOffer,
            pap_ids: papIdsTarget,
        });
    }
    return extras;
}

const ValidVendors = [
    'all',
    'nl',
    'be',
    'no',
    'se',
    'uk',
    'de',
    'fr',
    'at',
    'pxxlde',
    'pbxuk',
    'pbxfr',
    'pbxie',
    'pbxit',
    'pbxdk',
    'hofes',
    'hofpt',
    'any',
];

function cartParseStrategy(cartOffer: string): MappedDefinitionCartCondition {
    //for cart with 3 PHOTOBOOKS
    //for cart with 1 PAP_XXX, PAP_YYY (let's assume both photobook PAPs)
    const offer = cartOffer.toLowerCase().trim();
    const split = offer.split(KeywordSearch.CartSplit); //with
    const type = !KeywordSearch.CartValue.test(offer)
        ? MappedDefinitionCartConditionType.Quantity
        : MappedDefinitionCartConditionType.Value;
    const specificProducts = split[2].includes(Keyword.SpecificProductPrefix);

    let product;
    if (specificProducts) {
        // Get array of unique product types from pap_ids
        const papIds = parsePapIds(split[2]);
        product = papIds
            .map(papId => parseProductTargetByPapId(papId))
            .filter((value, index, array) => array.indexOf(value) === index);
    } else {
        // get product type from definition
        product = [parseProductTarget(split[2])];
    }
    //abusive use of parseInt and string contains to split # from target.
    return {
        amount: parseInt(split[2]), //3
        type: type,
        product, // photobooks
    };
}

function vendorParseStrategy(vendorSection: string): MappedDefinitionVendor {
    const vendorOffer = vendorSection
        .replace(KeywordSearch.VendorWord, '')
        .replace('\r', '')
        .replace('\n', '')
        .toLowerCase()
        .trim()
        .replace(/[\u200B-\u200D\uFEFF]/g, '');

    const vendors = vendorOffer.split(Keyword.VendorDivider); //,
    //simplify e.g. albelli.nl => nl
    const vendorsSimple = vendors.map(vendor => {
        vendor = vendor.trim();
        switch (vendor) {
            case 'posterxxl.de':
                return 'pxxlde';
                break;
            case 'photobox.co.uk':
                return 'pbxuk';
                break;
            case 'photobox.fr':
                return 'pbxfr';
                break;
            case 'photobox.ie':
                return 'pbxie';
                break;
            case 'photobox.it':
                return 'pbxit';
                break;
            case 'photobox.dk':
                return 'pbxdk';
                break;
            case 'hofmann.es':
                return 'hofes';
                break;
            case 'hofmann.pt':
                return 'hofpt';
                break;
            default:
                const split = vendor.split('.');
                return split[split.length - 1];
        }
    });
    //definition should already be validated, this is a little redundant.
    const supportedVendors = vendorsSimple.filter(vendor => ValidVendors.includes(vendor));
    const unsupportedVendors = vendorsSimple.filter(vendor => !ValidVendors.includes(vendor));
    if (unsupportedVendors.length) console.log('invalidVendors', unsupportedVendors);

    return {
        vendors: supportedVendors,
        cartCondition: null,
        offers: [],
    };
}

function shippingParseStrategy(shippingOffer: string): MappedDefinitionOffer {
    //100% discount to shipping
    //ToDo very basic implementation only covering % shipping offers
    //1 NOK discount to shipping
    const offer = shippingOffer.toLowerCase();

    if (offer.includes(Keyword.Percent)) {
        const amount = parsePercentage(offer);
        return {
            product: [MappedDefinitionOfferProductType.Shipping],
            type: MappedDefinitionOfferType.Percent,
            amount: amount,
            target: null,
            extras: null,
            definition: shippingOffer,
            pap_ids: null,
        };
    } else {
        return {
            product: [MappedDefinitionOfferProductType.UnknownShipping],
            type: MappedDefinitionOfferType.Unknown,
            amount: 0,
            target: null,
            extras: null,
            definition: shippingOffer,
            pap_ids: null,
        };
    }
}

function fixedPriceParseStrategy(productOffer: string): MappedDefinitionOffer {
    const offer = productOffer.toLowerCase();
    return {
        product: [MappedDefinitionOfferProductType.Unknown],
        type: MappedDefinitionOfferType.Unknown,
        amount: 0,
        target: MappedDefinitionOfferTarget.All,
        extras: null,
        definition: productOffer,
        pap_ids: null,
    };
}

function productPercentageParseStrategy(productOffer: string): Array<MappedDefinitionOffer> {
    const offer = productOffer.toLowerCase();
    if (!KeywordSearch.TargetSplit.test(offer)) {
        //if not applying it TO anything specific it is an ALL discount
        const amount = parsePercentage(offer);
        return [
            {
                product: [MappedDefinitionOfferProductType.All],
                type: MappedDefinitionOfferType.Percent,
                amount: amount,
                target: MappedDefinitionOfferTarget.All,
                extras: null,
                definition: productOffer,
                pap_ids: null,
            },
        ];
    }
    //if it includes the / then it is extras which are handled separately
    if (offer.includes(Keyword.ExtraDivider)) {
        return parseExtras(productOffer);
    }
    //e.g. 10% discount to total price of all CARDS
    const split = offer.split(KeywordSearch.TargetSplit); //to
    const amount = parsePercentage(split[0]); //todo this should also parse the fixe prices really..
    const targetAndProduct = split[2]; //e.g. total price of all CARDS
    const splitTarget = targetAndProduct.split(KeywordSearch.ProductSplit); //of
    const target = splitTarget[0]; //e.g. total price
    const products = splitTarget[2]; //e.g. all CARDS

    const totalPrice = KeywordSearch.TotalPrice.test(target); //total price
    const basePrice = KeywordSearch.BasePrice.test(target); //base price
    if (totalPrice || basePrice) {
        return parseProductList(products, amount, totalPrice, productOffer);
    }
    return [
        {
            product: [MappedDefinitionOfferProductType.Unknown],
            type: MappedDefinitionOfferType.Percent,
            amount: amount,
            target: MappedDefinitionOfferTarget.Unknown,
            extras: null,
            definition: productOffer,
            pap_ids: null,
        },
    ];
}

export function parseDefinition(discountCode: string, definition: string): MappedDefinition | null {
    if (!definition) return null;
    const result: MappedDefinition = {
        discountCode,
        vendorOffers: Array.of<MappedDefinitionVendor>(),
    };
    let offerBlock: MappedDefinitionVendor | null = null;
    let isOfferForAllProductsIncluded = false;

    const firstSplit = definition.split(KeywordSearch.VendorCartSplit); //for
    for (let i = 0, j = firstSplit.length; i < j; i++) {
        // reset offer block for each vendor
        isOfferForAllProductsIncluded = false;
        //firstSplit example
        //[for, vendor albelli.nl, for,
        // cart with 3 photobooks apply 20% discount to shipping apply 10% discount to total price of all CARDS',
        //for, vendor albelli.be apply ]
        const split = firstSplit[i].split(KeywordSearch.OfferSplit); //apply
        for (let k = 0, l = split.length; k < l; k++) {
            //firstSplit[0,1,2] don't include apply so are processed as array length 1
            //firstSplit[3] split example
            //[cart with 3 photobooks, apply, 20% discount to shipping, 10% discount to total price of all CARDS]
            const offer = split[k].trim();

            // offer = apply || offer = for
            if (!offer || KeywordSearch.OfferSplit.test(offer) || KeywordSearch.VendorCartSplit.test(offer)) continue;

            if (isOfferForAllProductsIncluded === true) {
                break;
            }

            //this rule is a stopper if apply to all
            if (KeywordSearch.AllWord.test(offer) && offer.indexOf(Keyword.AllProducts) >= 0) {
                isOfferForAllProductsIncluded = true;
            }

            //e.g. vendor albelli.nl
            if (KeywordSearch.VendorWord.test(offer)) {
                const vendors = vendorParseStrategy(offer);

                //vendor is the parent of the offer block, if this is not first vendor we push
                if (offerBlock != null) {
                    result.vendorOffers.push(offerBlock);
                }
                //and start a new offer block section
                offerBlock = {
                    vendors: vendors.vendors,
                    cartCondition: null,
                    offers: [],
                };
                continue;
            }
            if (offerBlock == null) {
                continue;
            }
            //e.g. cart with 3 photobooks
            if (KeywordSearch.CartWord.test(offer)) {
                const cartCondition = cartParseStrategy(offer);
                offerBlock.cartCondition = cartCondition;
                continue;
            }
            //e.g. 20% discount to shipping
            if (KeywordSearch.ShippingWord.test(offer)) {
                const shipping = shippingParseStrategy(offer);
                offerBlock.offers.push(shipping);
                continue;
            }
            //e.g. 10% discount to total price of all CARDS

            //ToDo - i think the product parse and fixed price parse should be the same function and the percentage/fixed parse done internally
            //It will also need to handle all possible value types eg. EUR NOK GBP
            //% was just the easiest to do first.
            if (offer.includes(Keyword.Percent)) {
                const percentage = productPercentageParseStrategy(offer);
                for (let k = 0, l = percentage.length; k < l; k++) {
                    offerBlock.offers.push(percentage[k]);
                }
                continue;
            }
            //e.g. 10 EUR discount to total price of all CARDS
            const price = fixedPriceParseStrategy(offer);
            offerBlock.offers.push(price);
        }
    }

    //we will have at least 1 offer block or else it is completely invalid
    if (offerBlock != null) {
        result.vendorOffers.push(offerBlock);
    }
    for (let i = 0, j = result.vendorOffers.length; i < j; i++) {
        const element = result.vendorOffers[i];
        if (element.vendors.includes('de')) element.vendors.push('pxxlde');

        //Refine offers to remove deduplication when article_types 'all' and more than one offer in the OfferBlock
        const indexedOfferBlock = refineOffers(result.vendorOffers[i]);
        if (indexedOfferBlock != null) {
            result.vendorOffers[i] = indexedOfferBlock;
        }
    }
    return result;
}

/**
 * Refine offers
 *  - For offers with all article types, this function removes previously used article types
 *
 * @param offerBlock MappedDefinitionVendor | null
 * @returns MappedDefinitionVendor | null
 */
export const refineOffers = (offerBlock: MappedDefinitionVendor | null): MappedDefinitionVendor | null => {
    if (offerBlock && offerBlock.offers.length > 1) {
        const articleTypesWithOffer = offerBlock.offers.flatMap(o => o.product);

        //find offer with all products
        const index = offerBlock.offers.findIndex(
            o => !!o.product.find(product => KeywordSearch.AllWord.test(product)),
        );
        //duplicate offer
        const newOffer = { ...offerBlock.offers[index] };
        //add all article types excluding the other offers article types
        newOffer.product = mappedDefinitionAllArticleTypes.filter(
            a => !articleTypesWithOffer.includes(a as MappedDefinitionOfferProductType),
        ) as MappedDefinitionOfferProductType[];

        offerBlock.offers[index] = newOffer;

        //do not leave offers without products
        offerBlock.offers = offerBlock.offers.filter(offer => {
            return offer.product.length;
        });
    }
    return offerBlock;
};
