import { Theme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { setShowStatusBar } from 'application/campaignForm/campaignCreate/visualFeedbackStore';
import { requestConfig } from 'application/config/configActions';
import { StatusSnackbar, StatusSnackbarProps } from 'components/campaigns/CampaignForm/StatusSnackbar';
import { CustomColors } from 'components/common/CustomTheme/CustomTheme';
import { Loader } from 'components/common/Loader/Loader';
import { isLoading, isNotRequested } from 'helpers/requestStateHelper';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'store';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme: Theme) => ({
    root: {
        backgroundColor: CustomColors.background,
    },
}));

export const loadNewRelic = (callback: Function): void => {
    const existingScript = document.getElementById('newRelic');

    if (!existingScript) {
        const script = document.createElement('script');
        script.src = new URL('newrelic.js', window.location.origin).toString();
        script.id = 'newRelic';
        document.body.appendChild(script);
    }

    if (existingScript && callback) callback();
};

export const Bootstrap: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const dispatch = useDispatch();
    const config = useSelector(state => state.config);
    const { classes } = useStyles();
    const visualFeedbackElements = useSelector(state => state.visualFeedbackElements);
    useEffect(() => {
        if (isNotRequested(config)) {
            dispatch(requestConfig());
        }
    }, [config]);

    if (isLoading(config)) {
        return (
            <div>
                <Loader />
            </div>
        );
    }
    const statusSnackbarProps: StatusSnackbarProps = {
        open: visualFeedbackElements.showStatusBar,
        text: visualFeedbackElements.statusBarContent,
        handleClose: () => {
            dispatch(setShowStatusBar(false));
        },
        severity: visualFeedbackElements.severity,
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StatusSnackbar {...statusSnackbarProps} />
            <div className={classes.root}>{children}</div>
        </LocalizationProvider>
    );
};
