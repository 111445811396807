export const routes = {
    home: '/',
    campaigns: '/campaigns',
    campaignsNew: '/campaigns/:channel/new',
    campaignsEdit: '/campaigns/:channel/edit/:id',
    campaignsChannel: '/campaigns/:channel',
    vouchers: '/vouchers',
    bnclCampaigns: '/vouchers/bncl',
    newBnclCampaign: '/vouchers/bncl/new-campaign',
    editBnclCampaign: '/vouchers/bncl/campaign/:campaignId',
    discounts: '/discounts',
    discountsNew: '/discounts/new',
    discountsEdit: '/discounts/edit/:id',
    addAttributionSource: '/discounts/attribution/source/add',
    seo: '/seo',
    robots: '/seo/robots',
    sitemap: '/seo/sitemap',
    redirects: '/seo/redirects',
    seoPages: '/seo/pages',
    seoPagesEditorNew: '/seo/pages/editor/new',
    seoPagesEditorEdit: '/seo/pages/editor/:pageId',
    sfpMetadataEdit: '/seo/pages/sfp-edit/:pageId',
    b2b: '/b2b',
    lod: '/lod',
    lodSettings: '/lod/settings',
};
