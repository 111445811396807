import {
    EmailOutlined,
    FindInPageOutlined,
    LocalMallOutlined,
    MonetizationOn,
    RecentActors,
} from '@mui/icons-material';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import WebIcon from '@mui/icons-material/Web';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { CampaignChannels } from 'models/campaign';
import { LODOptions } from 'models/lod';
import { SEOOptions } from 'models/seo';
import { routes } from 'routes';

export interface SideNavigationItem {
    icon: OverridableComponent<SvgIconTypeMap<object, 'svg'>>;
    selected?: boolean;
    text: string;
    url: string;
}

export const navItems: Record<string, Record<string, SideNavigationItem>> = {
    [routes.discounts]: {
        manageDiscounts: {
            icon: LocalOfferIcon,
            text: 'Discounts',
            url: '',
        },
        attributionAdd: { icon: AddCircleOutlineOutlinedIcon, text: 'Add Attribution', url: 'attribution/source/add' },
    },
    [routes.vouchers]: {
        bncl: {
            icon: LocalActivityIcon,
            text: 'BNCL Campaigns',
            url: 'bncl',
        },
    },
    [routes.seo]: {
        [SEOOptions.robots]: {
            icon: ComputerOutlinedIcon,
            text: 'Robots',
            url: 'robots',
        },
        [SEOOptions.sitemap]: {
            icon: AccountTreeOutlinedIcon,
            text: 'Sitemap',
            url: 'sitemap',
        },
        [SEOOptions.redirects]: {
            icon: CallSplitIcon,
            text: 'Redirects',
            url: 'redirects',
        },
        [SEOOptions.seoPages]: {
            icon: DescriptionIcon,
            text: 'Pages',
            url: SEOOptions.seoPages,
        },
    },
    [routes.lod]: {
        [LODOptions.settings]: {
            icon: SettingsOutlinedIcon,
            text: 'Settings',
            url: 'settings',
        },
    },
    [routes.campaigns]: {
        [CampaignChannels.merchandising]: {
            icon: LocalMallOutlined,
            text: 'Merch',
            url: CampaignChannels.merchandising,
        },
        [CampaignChannels.crm]: { icon: EmailOutlined, text: 'CRM', url: CampaignChannels.crm },
        [CampaignChannels.sea]: {
            icon: FindInPageOutlined,
            text: 'SEA (& Shopping)',
            url: CampaignChannels.sea,
        },
        [CampaignChannels.sea_exp]: {
            icon: FindInPageOutlined,
            text: 'SEA Experiments',
            url: CampaignChannels.sea_exp,
        },
        [CampaignChannels.display]: { icon: WebIcon, text: 'Display (& Shopping)', url: CampaignChannels.display },
        [CampaignChannels.display_exp]: {
            icon: WebIcon,
            text: 'Display Experiments',
            url: CampaignChannels.display_exp,
        },
        [CampaignChannels.partner]: { icon: RecentActors, text: 'Partner', url: CampaignChannels.partner },
        [CampaignChannels.affiliate]: {
            icon: MonetizationOn,
            text: 'Affiliate',
            url: CampaignChannels.affiliate,
        },
    },
};

export const generateSideNavItems = (baseUrl: string): SideNavigationItem[] | [] => {
    if (navItems[baseUrl]) {
        return Object.keys(navItems[baseUrl]).map(key => {
            const item = navItems[baseUrl][key];
            const url = `${baseUrl}/${item.url}`;
            const itemProps: SideNavigationItem = { ...item, url: url, selected: false };

            return itemProps;
        });
    } else {
        return [];
    }
};
