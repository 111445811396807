import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getLodFiles, postLodFiles, SettingsUpdate, LodFileType } from 'apis/lod';
import { createGenericExtraReducers } from 'common/createGenericExtraReducers';
import { getInitialRequestState, RequestType } from 'common/RequestState';

export const fetchLodSettings = createAsyncThunk('lod/settings/get', async (): Promise<LodFileType> => {
    const files = await getLodFiles();
    for (const vendor in files) {
        for (const type in files[vendor]) {
            if (typeof files[vendor][type] === 'object') {
                files[vendor][type] = JSON.stringify(files[vendor][type], null, 2);
            }
        }
    }

    return files;
});

export const postLodSettings = createAsyncThunk(
    'lod/settings/post',
    async (updatePayload: SettingsUpdate, thunkApi) => {
        await postLodFiles(updatePayload);
        return updatePayload;
    },
);

export const lodSettingsSlice = createSlice({
    name: 'settings',
    initialState: {
        settings: <RequestType<LodFileType, LodFileType>>getInitialRequestState({}),
    },
    reducers: {},
    extraReducers: builder => {
        createGenericExtraReducers(builder, fetchLodSettings, 'settings');
    },
});
