import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit';
import {
    getDiscount,
    getDiscountByCode,
    getDiscounts,
    postDiscount,
    PromotionsPaginatedData,
    putDiscount,
} from 'apis/promotionsApi';
import { createGenericExtraReducers } from 'common/createGenericExtraReducers';
import { RequestState as RequestStateType } from 'common/genericTypes';
import { RequestState } from 'common/RequestState';
import DiscountCodeError from 'errors/DiscountCodeError';
import { SortingOrder } from 'models/discount';
import { DiscountCodeType, DiscountDefinition, DiscountGenerateCodes, DiscountsForm } from 'models/discounts/discount';
import { createAttribution, retrieveAttributionByDiscountUuid } from './discountsAttributionSlice';
import {
    createDiscountCodes,
    generateDiscountCodes,
    removeDiscountCode,
    retrieveDiscountCodesByDiscountUuid,
    validateDiscountCode,
} from './discountsCodeSlice';

export type DiscountFormSubmission = {
    discount: DiscountDefinition;
    discountCodes: DiscountCodeType[];
};

export const createDiscount = createAsyncThunk(
    'discount/create',
    async ({ discount, discountCodes }: DiscountFormSubmission, thunkAPI) => {
        const savedDiscount = await postDiscount(discount);

        if (discount.codeGenerationMode === DiscountGenerateCodes.manually) {
            // Bulk generate for codes require a discount ID. Discount can be created without codes
            const discountCodeObjects = discountCodes.map(codeObj => {
                return { code: codeObj.code, discountUUID: savedDiscount.id as string } as DiscountCodeType;
            });
            const codesResult = await thunkAPI.dispatch(createDiscountCodes(discountCodeObjects));
            if (codesResult.meta.requestStatus === 'rejected') {
                throw (codesResult as any).error;
            }
        }

        if (discount.codeGenerationMode === DiscountGenerateCodes.bulk && discount.amountOfCodes.length > 0) {
            const codesResult = await thunkAPI.dispatch(
                generateDiscountCodes({
                    discountUUID: savedDiscount.id as string,
                    amountOfCodes: discount.amountOfCodes,
                }),
            );
            if (codesResult.meta.requestStatus === 'rejected') {
                throw (codesResult as any).error;
            }
        }

        return savedDiscount;
    },
);

export const updateDiscount = createAsyncThunk(
    'discount/update',
    async ({ discount, discountCodes }: DiscountFormSubmission, thunkAPI) => {
        const updatedDiscount = await putDiscount(discount);

        //format discount codes
        if (discount.codeGenerationMode === DiscountGenerateCodes.manually) {
            // Bulk generate for codes require a discount ID. Discount can be created without codes
            const discountCodeObjects = discountCodes.map(codeObj => {
                return { code: codeObj.code, discountUUID: discount.id as string } as DiscountCodeType;
            });
            const codesResult = await thunkAPI.dispatch(createDiscountCodes(discountCodeObjects));
            if (codesResult.meta.requestStatus === 'rejected') {
                throw (codesResult as any).error;
            }
        }
        if (discount.codeGenerationMode === DiscountGenerateCodes.bulk && discount.amountOfCodes.length > 0) {
            const codesResult = await thunkAPI.dispatch(
                generateDiscountCodes({
                    discountUUID: discount.id as string,
                    amountOfCodes: discount.amountOfCodes,
                }),
            );
            if (codesResult.meta.requestStatus === 'rejected') {
                throw (codesResult as any).error;
            }
        }

        return updatedDiscount;
    },
);

export const retrieveDiscount = createAsyncThunk('discount/get', async (discountUuid: string) => {
    const discount = await getDiscount(discountUuid);
    return discount;
});

export enum DiscountSearchType {
    ID = 'ID',
    CODE = 'CODE',
}
export interface DiscountSearchArgs {
    searchTerm: string;
    type: DiscountSearchType;
    clear: boolean;
}

const searchHandler = async (discountSearchArgs: DiscountSearchArgs) => {
    let discount;
    const discounts: PromotionsPaginatedData<DiscountDefinition> = { items: {}, batch: 0, total: 0 };
    switch (discountSearchArgs.type) {
        case DiscountSearchType.CODE:
            const discountByCode = await getDiscountByCode(discountSearchArgs.searchTerm);
            if (discountByCode && discountByCode.id) {
                discount = await getDiscount(discountByCode.id);
            }
            break;
        case DiscountSearchType.ID:
            discount = await getDiscount(discountSearchArgs.searchTerm);
            break;
    }

    if (discount) {
        discounts.items[0] = discount;
        discounts.batch = 1;
        discounts.total = 1;
    }

    return discounts;
};
export const searchDiscount = createAsyncThunk('discount/search', searchHandler);

const selectors = {
    discountForm: state => state.discountForm as DiscountsForm,
};
export interface CompletedFormStepArgs {
    currentStep: number;
    nextStep: number;
}
export const completeFormStep = createAsyncThunk(
    'discountForm/completeFormStep',
    async (args: CompletedFormStepArgs, thunkAPI) => {
        const discountForm: DiscountsForm = selectors.discountForm(thunkAPI.getState());
        return {
            ...selectors.discountForm(thunkAPI.getState()),
            formStep: args.nextStep,
            completedSteps: [...new Set(discountForm.completedSteps).add(args.currentStep)],
        };
    },
);

export const switchDiscountFormStep = createAsyncThunk('discountForm/switchStep', async (step: number, thunkAPI) => {
    const discountForm: DiscountsForm = selectors.discountForm(thunkAPI.getState());
    return {
        ...discountForm,
        formStep: step,
        completedSteps: [...discountForm.completedSteps.filter(filterStep => filterStep !== step)],
    };
});

export const completeDiscountFormSubmission = createAsyncThunk('discountForm', async (_, thunkAPI) => {
    return { ...selectors.discountForm(thunkAPI.getState()), formStep: 2, completedSteps: [1] };
});

const initialState: DiscountsForm = {
    requestState: RequestState.NotRequested,
    formStep: 1,
    discount: { requestState: RequestState.NotRequested },
    completedSteps: [],
    errors: {},
    discountCodes: { requestState: RequestState.NotRequested },
    attribution: { requestState: RequestState.NotRequested },
    codeGenerationMode: DiscountGenerateCodes.manually,
    amountToGenerate: '',
};

export const discountFormSlice = createSlice({
    name: 'discountsForm',
    initialState,
    reducers: {
        reset: () => initialState,
    },
    extraReducers: builder => {
        createGenericExtraReducers(builder, createDiscountCodes, 'discountCodes');
        builder.addCase(completeDiscountFormSubmission.pending, (state, action) => {
            state = Object.assign(state, action.payload);
            state.requestState = RequestState.InProgress;
        });
        builder.addCase(completeDiscountFormSubmission.fulfilled, (state, action) => {
            state = Object.assign(state, action.payload);
            state.requestState = RequestState.Finished;
        });
        builder.addCase(completeDiscountFormSubmission.rejected, (state, action) => {
            state = Object.assign(state, action.payload);
            state.errors = [action.error];
            state.requestState = RequestState.Failed;
        });
        /*
         * VALIDATE DISCOUNT CODE
         */
        builder.addCase(validateDiscountCode.pending, (state, action) => {
            state.discountCodes.requestState = RequestState.InProgress;
        });
        builder.addCase(validateDiscountCode.fulfilled, (state, action) => {
            const codes = new Set(state.discountCodes.codes);
            if (action.payload.validatedCode) {
                codes.add(action.payload.validatedCode);
            }

            state.discountCodes = {
                errors: action.payload.errors ? [action.payload.errors] : [],
                codes: [...codes],
                requestState: RequestState.Finished,
            };
        });
        builder.addCase(validateDiscountCode.rejected, (state, action) => {
            state.discountCodes.errors = [
                new DiscountCodeError(action.error.message ?? '', {
                    conflict: false,
                    discountDefinitionId: '',
                    discountCode: action.meta.arg,
                    isOldDiscount: false,
                }),
            ];
            state.discountCodes.requestState = RequestState.Failed;
        });
        /**
         * ATTRIBUTION
         */
        builder.addMatcher(
            isAnyOf(createAttribution.pending, retrieveAttributionByDiscountUuid.pending),
            (state, action) => {
                state.attribution.requestState = RequestState.InProgress;
            },
        );
        builder.addMatcher(
            isAnyOf(createAttribution.fulfilled, retrieveAttributionByDiscountUuid.fulfilled),
            (state, action) => {
                state.attribution = { ...action.payload, requestState: RequestState.Finished };
            },
        );
        builder.addMatcher(
            isAnyOf(createAttribution.rejected, retrieveAttributionByDiscountUuid.rejected),
            (state, action) => {
                state.attribution.error = action.error.message;
                state.attribution.requestState = RequestState.Failed;
            },
        );
        /**
         * DISCOUNT FORM
         */
        builder.addMatcher(isAnyOf(completeFormStep.pending, switchDiscountFormStep.pending), (state, action) => {
            state = Object.assign(state, action.payload);
            state.requestState = RequestState.InProgress;
        });
        builder.addMatcher(isAnyOf(completeFormStep.fulfilled, switchDiscountFormStep.fulfilled), (state, action) => {
            state = Object.assign(state, action.payload);
            state.requestState = RequestState.Finished;
        });
        builder.addMatcher(isAnyOf(completeFormStep.rejected, switchDiscountFormStep.rejected), (state, action) => {
            state = Object.assign(state, action.payload);
            state.errors = [action.error];
            state.requestState = RequestState.Failed;
        });
        /**
         * DISCOUNT
         */
        builder.addMatcher(isAnyOf(createDiscount.pending, updateDiscount.pending, retrieveDiscount.pending), state => {
            state.discount.requestState = RequestState.InProgress;
        });
        builder.addMatcher(
            isAnyOf(createDiscount.fulfilled, updateDiscount.fulfilled, retrieveDiscount.fulfilled),
            (state, action) => {
                state.discount = { ...action.payload, requestState: RequestState.Finished };
            },
        );
        builder.addMatcher(
            isAnyOf(createDiscount.rejected, updateDiscount.rejected, retrieveDiscount.rejected),
            (state, action) => {
                state.discount.error = action.error.message;
                state.discount.requestState = RequestState.Failed;
            },
        );
        /**
         * DISCOUNT CODES
         */
        builder.addMatcher(
            isAnyOf(
                createDiscountCodes.pending,
                generateDiscountCodes.pending,
                retrieveDiscountCodesByDiscountUuid.pending,
                removeDiscountCode.pending,
            ),
            state => {
                state.discountCodes.requestState = RequestState.InProgress;
            },
        );
        builder.addMatcher(
            isAnyOf(
                createDiscountCodes.fulfilled,
                generateDiscountCodes.fulfilled,
                retrieveDiscountCodesByDiscountUuid.fulfilled,
                removeDiscountCode.fulfilled,
            ),
            (state, action) => {
                state.discountCodes = {
                    ...action.payload,
                    requestState: RequestState.Finished,
                    codes: action.payload.codes || undefined,
                };
            },
        );
        builder.addMatcher(
            isAnyOf(
                createDiscountCodes.rejected,
                generateDiscountCodes.rejected,
                retrieveDiscountCodesByDiscountUuid.rejected,
                removeDiscountCode.rejected,
            ),
            state => {
                state.discountCodes.requestState = RequestState.Failed;
            },
        );
    },
});

export type DiscountFetchProps = {
    validAt: string;
    page: number;
    sortBy?: string;
    sortingOrder?: SortingOrder;
};

export const fetchDiscounts = createAsyncThunk(
    'discounts',
    async ({ validAt, page, sortBy, sortingOrder }: DiscountFetchProps) => {
        const resultsPerPage = 10;
        const skip = page * resultsPerPage;
        return await getDiscounts(validAt, resultsPerPage, skip, sortBy, sortingOrder);
    },
);

export interface DiscountsState extends RequestStateType {
    payload: PromotionsPaginatedData<DiscountDefinition>;
    search: DiscountSearchArgs | object;
}

export const discountsSlice = createSlice({
    name: 'discounts',
    initialState: {
        requestState: RequestState.NotRequested,
        payload: {},
        search: {},
    },
    reducers: {},
    extraReducers: builder => {
        /**
         * DISCOUNT SEARCH
         */
        builder.addCase(searchDiscount.pending, (state, action) => {
            state.search = { searchTerm: action.meta.arg.searchTerm, type: action.meta.arg.type };
            state.requestState = RequestState.InProgress;
        });
        builder.addCase(searchDiscount.fulfilled, (state, action) => {
            state.payload = action.payload;
            state.search = { searchTerm: action.meta.arg.searchTerm, type: action.meta.arg.type };
            state.requestState = RequestState.Finished;
        });
        builder.addCase(searchDiscount.rejected, (state, action) => {
            state.requestState = RequestState.Failed;
            state.search = { searchTerm: action.meta.arg.searchTerm, type: action.meta.arg.type };
        });
        /**
         * DISCOUNT LOAD & AFTER CREATION
         */
        builder.addMatcher(isAnyOf(fetchDiscounts.pending), state => {
            state.requestState = RequestState.InProgress;
        });
        builder.addMatcher(isAnyOf(fetchDiscounts.fulfilled), (state, action) => {
            state.payload = action.payload;
            state.search = {};
            state.requestState = RequestState.Finished;
        });
        builder.addMatcher(isAnyOf(fetchDiscounts.rejected), (state, action) => {
            state.requestState = RequestState.Failed;
        });
    },
});
