import { createAsyncThunk } from '@reduxjs/toolkit';
import {
    deleteDiscountCode,
    getDiscountByCode,
    getDiscountCodesByDiscountUuid,
    getOldDiscountByCode,
    postDiscountCode,
    postGenerateDiscountCode,
} from 'apis/promotionsApi';
import DiscountCodeError from 'errors/DiscountCodeError';
import {
    DiscountCodeGenerate,
    DiscountCodesPayload,
    DiscountCodeType,
    DiscountDefinition,
    DiscountStatus,
} from 'models/discounts/discount';
import { completeDiscountFormSubmission } from './discountsSlice';

export const validateDiscountCode = createAsyncThunk('discountCode/validate', async (discountCode: string) => {
    let codesErrors: DiscountCodeError | null = null;
    let validatedCode: DiscountCodeType | null = null;

    try {
        const discountDefinition = await getDiscountByCode(discountCode);
        const oldDiscount = await getOldDiscountByCode(discountCode);
        const now = new Date();
        const validTo = new Date(discountDefinition?.validTo ?? 0);
        const newValidDiscountExists = discountDefinition?.status === DiscountStatus.published && validTo > now;
        if (newValidDiscountExists || oldDiscount) {
            codesErrors = new DiscountCodeError('Discount Code already exists', {
                conflict: true,
                discountDefinitionId: discountDefinition?.id as string,
                discountCode: discountCode,
                isOldDiscount: !!oldDiscount,
            });
        } else {
            validatedCode = { code: discountCode, discountUUID: discountDefinition?.id, timestamp: undefined };
        }
    } catch (error) {
        const codesErrors = new DiscountCodeError(error as string, {
            conflict: false,
            discountDefinitionId: '',
            discountCode: discountCode,
            isOldDiscount: false,
        });
    }

    return { validatedCode, errors: codesErrors };
});

export const removeDiscountCode = createAsyncThunk('discountCode/delete', async (discountCode: string, thunkApi) => {
    const discountCodes: Partial<DiscountCodesPayload> = (thunkApi.getState() as any).discountForm.discountCodes;
    const discount: Partial<DiscountDefinition> = (thunkApi.getState() as any).discountForm.discount;
    if (discount.id) {
        const existingDiscount = await getDiscountByCode(discountCode);
        if (existingDiscount && existingDiscount.id === discount.id) {
            await deleteDiscountCode(discountCode, discount.id);
        }
    }
    const codes = discountCodes && discountCodes.codes ? discountCodes.codes.filter(c => c.code !== discountCode) : [];
    return { codes: codes, errors: discountCodes.errors };
});

export const createDiscountCodesInBulk = async (discountCodes: DiscountCodeType[], thunkAPI) => {
    const codesErrors: DiscountCodeError[] = [];
    const codesCreated: DiscountCodeType[] = [];
    if (discountCodes.length) {
        for (const discountCode of discountCodes) {
            await postDiscountCode(discountCode.code, discountCode.discountUUID || '');
            codesCreated.push({
                code: discountCode.code,
                discountUUID: discountCode.discountUUID,
                timestamp: discountCode.timestamp,
            });
        }
    }

    if (!codesErrors.length) {
        await thunkAPI.dispatch(completeDiscountFormSubmission());
    }
    return { codes: codesCreated, errors: codesErrors };
};

export const createDiscountCodes = createAsyncThunk('discountCodes/create', createDiscountCodesInBulk);

const API_CODE_CREATING_LIMIT = 2000;
export const generateDiscountCodes = createAsyncThunk(
    'discountCodes/generate',
    async (discountData: DiscountCodeGenerate, thunkAPI) => {
        let leftCodesToCreate = Number(discountData.amountOfCodes);
        while (leftCodesToCreate > 0) {
            const amountToCreate = leftCodesToCreate > API_CODE_CREATING_LIMIT ? 2000 : leftCodesToCreate;
            await postGenerateDiscountCode(discountData.discountUUID, amountToCreate.toString());
            leftCodesToCreate = leftCodesToCreate - amountToCreate;
        }

        const batchCodes = await getDiscountCodesByDiscountUuid(discountData.discountUUID);

        return { codes: batchCodes };
    },
);

export const retrieveDiscountCodesByDiscountUuid = createAsyncThunk(
    'discountCodes/get',
    async (discountUuid: string) => {
        const discounts = await getDiscountCodesByDiscountUuid(discountUuid);
        return { codes: discounts };
    },
);

export const retrieveDiscountByDiscountCode = createAsyncThunk('discountCodes/get', async (discountCode: string) => {
    const discounts = await getDiscountByCode(discountCode);
    return discounts;
});
